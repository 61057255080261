<template>
    <div class="row">
        <div :class="(page == 'dashboard') ? 'col-12 col-sm-6 col-md-3 col-lg-2  mb-3 py-0' : 'col-12 col-sm-6 col-md-3 mb-3 py-0'"
            v-for="token in tokens" :key="token.name">
            <div v-if="token.vouchers" id="test" @click="show = !show"
                :class="['card-shadow-' + token.color, 'widget-chart widget-chart2 card-btm-border', 'card-shadow-' + token.color, 'text-left', 'border-' + token.color, 'card']"
                data-toggle="tooltip" data-placement="top">
                <div class="widget-chat-wrapper-outer">
                    <div class="widget-chart-content">
                        <h6 class="widget-subheading ">{{ token.name }}</h6>
                        <div class="widget-numbers mb-0 w-100">
                            <div class="widget-chart-flex">
                                <div id="vch" class="fsize-2"><span id="test2">{{ token.value }}</span></div>
                                <b-tooltip target="test2" triggers="hover" placement="bottom" custom-class="tooltip"
                                    v-if="vouchers">
                                    <b-list-group>
                                        <b-list-group-item @click="goToZlatibor"
                                            :style="{ 'margin-top': v.paddingTop, 'margin-bottom': v.paddingBottom }"
                                            style="cursor: pointer; background-color: transparent; border: none; padding-top: 0; padding-bottom: 0"
                                            v-for="v in vouchersFull" :key="v._id">
                                            {{ v.ime }}
                                            <hr v-if="v.divider" style="border-top: 1px white solid">
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else
                :class="['card-shadow-' + token.color, 'widget-chart widget-chart2 card-btm-border', 'card-shadow-' + token.color, 'text-left', 'border-' + token.color, 'card']">
                <div class="widget-chat-wrapper-outer">
                    <div class="widget-chart-content">
                        <h6 class="widget-subheading "><span style="margin-right: 15px;">{{ token.name }}</span>
                        <div v-if="token.description">
                            <h6 ><i>{{ token.description }}</i></h6>
                        </div>
                            <available-to-shares-b-modal v-if="token.link" />
                        </h6>
                        <div class="widget-numbers mb-0 w-100">
                            <div class="widget-chart-flex">
                                <div class="fsize-2" v-if="token.eur">{{ (token.value * htlEur).toFixed(2) }}</div>
                                <div class="fsize-2" v-else>{{ (token.value).toFixed(2) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import gql from "graphql-tag";
import { mapGetters } from "vuex";
import AvailableToSharesBModal from '../modals/AvailableToSharesBModal'

export default {
    name: "TokenList",
    components: {
        AvailableToSharesBModal
    },
    props: [
        "page",
        "tokenList",
        "dailyLoyalty",
        "dailyLoyaltyUSDT"
    ],
    data() {
        return {
            loading: true,
            error: false,
            vouchers: null,
            voucherValue: 0,
        }
    },
    computed: {
        ...mapGetters({
            htlEur: 'prices/htlEur'
        }),
        tokens() {
            switch (this.page) {
                case "dashboard":
                    return [
                        {
                            name: this.$i18n.t("tokens-available"),
                            value: (this.tokenList.tokens) / 10e7,
                            cls: "available",
                            icon: "coins",
                            color: "primary",
                            link: "1"
                        },
                        {

                            name: "USDT Available",
                            value: this.tokenList.usdt / 10e7,
                            cls: "packages",
                            icon: "coins",
                            color: "primary"
                        },
                        {
                            name: this.$i18n.t("tokens-packages"),
                            description: "(Loyalty program)",
                            value: (this.tokenList.packages) / 10e7,
                            cls: "packages",
                            icon: "coins",
                            color: "primary"
                        },
                        {
                            name: "HTL in Freedom Program",
                            value: ((this.tokenList.freedom) ? this.tokenList.freedom : 0) / 10e7,
                            cls: "packages",
                            icon: "coins",
                            color: "primary"
                        },
                        {
                            name: this.$i18n.t("tokens-sharesA"),
                            description: "(Dividend program)",
                            value: (this.tokenList.sharesA - this.tokenList.promotionalSharesA) / 10e7,
                            cls: "sharesA",
                            icon: "file-invoice",
                            color: "secondary"
                        },
                        {
                            name: "Promotional HTL A",
                            value: (this.tokenList.promotionalSharesA) / 10e7,
                            cls: "sharesA",
                            icon: "file-invoice",
                            color: "secondary"
                        },
                        {
                            name: this.$i18n.t("tokens-monthly"),
                            value: (this.dailyLoyalty) / 10e7,
                            cls: "daily",
                            icon: "coins",
                            color: "success"
                        },
                        {
                            name: this.$i18n.t("tokens-loyalty"),
                            value: (this.tokenList.totalLoyalties) / 10e7,
                            cls: "accumulated",
                            icon: "coins",
                            color: "success"
                        },
                        {
                            name: "Monthly Loyalty USDT",
                            value: (this.dailyLoyaltyUSDT) / 10e7,
                            cls: "daily",
                            icon: "coins",
                            color: "success"
                        },
                        {
                            name: "Cummulated Loyalty USDT",
                            value: (this.tokenList.totalLoyaltiesUSDT) / 10e7,
                            cls: "accumulated",
                            icon: "coins",
                            color: "success"
                        },
                        {
                            name: this.$i18n.t('tokens-dividends'),
                            value: (this.tokenList.cummulatedDividends) / 1e8,
                            cls: "dividends",
                            icon: "file-invoice",
                            color: "secondary"
                        },
                        {
                            name: this.$i18n.t("tokens-total"),
                            value: (this.tokenList.tokens + this.tokenList.shares + this.tokenList.sharesA + this.tokenList.held + this.tokenList.packages + this.tokenList.freedom) / 10e7,
                            cls: "total",
                            icon: "coins",
                            color: "primary"
                        },
                    ];
                case "wallet":
                    return [
                        {
                            name: this.$i18n.t("tokens-available"),
                            value: (this.tokenList.tokens) / 10e7,
                            cls: "available",
                            icon: "coins",
                            color: "primary"
                        },
                        {

                            name: "USDT Available",
                            value: this.tokenList.usdt / 10e7,
                            cls: "packages",
                            icon: "coins",
                            color: "primary"
                        },
                        // {
                        //     name: this.$i18n.t("tokens-packages"),
                        //     value: (this.tokenList.packages) / 10e7,
                        //     cls: "packages",
                        //     icon: "coins",
                        //     color: "primary"
                        // },
                        // {
                        //     name: "HTL in Freedom Program",
                        //     value: ((this.tokenList.freedom) ? this.tokenList.freedom : 0) / 10e7,
                        //     cls: "packages",
                        //     icon: "coins",
                        //     color: "primary"
                        // },
                        // {
                        //     name: this.$i18n.t("tokens-total"),
                        //     value: (this.tokenList.tokens + this.tokenList.shares + this.tokenList.sharesA + this.tokenList.held + this.tokenList.packages + this.tokenList.freedom) / 10e7,
                        //     cls: "total",
                        //     icon: "coins",
                        //     color: "primary"
                        // },
                        {
                            name: "Vouchers",
                            value: this.voucherValue,
                            cls: "total",
                            icon: "coins",
                            vouchers: true,
                            color: "primary"
                        },
                        {
                            name: this.$i18n.t('tokens-withdrawals'),
                            value: (this.tokenList.withdrawn) / 1e4,
                            cls: "withdrawals",
                            icon: "file-invoice",
                            color: "secondary"
                        },
                        {
                            name: this.$i18n.t("tokens-comm"),
                            value: (this.tokenList.totalMatchingCommission + this.tokenList.totalDirectCommission + this.tokenList.totalBinaryCommission) / 10e7,
                            cls: "binary",
                            icon: "chart-line",
                            dashboard: false,
                            wallet: true,
                            color: "success"
                        },
                        {
                            name: this.$i18n.t("tokens-binary-comm"),
                            value: (this.tokenList.totalBinaryCommission) / 10e7,
                            cls: "binary",
                            icon: "chart-line",
                            color: "success"
                        },
                        {
                            name: this.$i18n.t("tokens-direct-comm"),
                            value: (this.tokenList.totalDirectCommission) / 10e7,
                            cls: "direct",
                            icon: "chart-line",
                            dashboard: false,
                            wallet: true,
                            color: "success"
                        },
                        {
                            name: this.$i18n.t("tokens-matching-comm"),
                            value: (this.tokenList.totalMatchingCommission) / 10e7,
                            cls: "binary",
                            icon: "chart-line",
                            dashboard: false,
                            wallet: true,
                            color: "success"
                        },

                        // {
                        //     name: this.$i18n.t("tokens-sharesA"),
                        //     value: (this.tokenList.sharesA - this.tokenList.promotionalSharesA) / 10e7,
                        //     cls: "sharesA",
                        //     icon: "file-invoice",
                        //     color: "secondary"
                        // },
                        // {
                        //     name: "Promotional HTL A",
                        //     value: (this.tokenList.promotionalSharesA) / 10e7,
                        //     cls: "sharesA",
                        //     icon: "file-invoice",
                        //     color: "secondary"
                        // },
                        // {
                        //     name: this.$i18n.t("tokens-sharesB"),
                        //     value: (this.tokenList.shares + this.tokenList.held) / 10e7,
                        //     cls: "sharesB",
                        //     icon: "file-invoice",
                        //     color: "secondary"
                        // },
                        // {
                        //     name: this.$i18n.t('tokens-dividends'),
                        //     value: (this.tokenList.cummulatedDividends) / 1e8,
                        //     cls: "dividends",
                        //     icon: "file-invoice",
                        //     color: "secondary"
                        // },

                    ];
                default:
                    return []
            }

        },
        vouchersFull() {
            var tmp = [...this.vouchers]



            tmp.map((el, index) => {
                var props = {};
                if (index == 0)
                    props.paddingTop = "1em"
                if (this.vouchers[index + 1] != null)
                    props.divider = true
                if (index === tmp.length - 1)
                    props.paddingBottom = "1em"
                return Object.assign(el, props)
            });
            console.log("Rendram...")
            console.log(tmp)
            return tmp
        }
    },
    mounted() {
        this.loading = false;
        this.axios.post(this.baseURL + '/admin-api/v0/bc/vavcerji?access_token=' + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token'))).then((response) => {

            var sum = 0;
            var tmp = response.data.filter(v => !v.porabljen)
            response.data.forEach((val) => {
                if (val.porabljen == false) {
                    //console.log(val.vrednost)
                    sum += parseInt(val.vrednost)
                }
            });

            this.vouchers = tmp
            this.voucherValue = sum

        })
    },
    methods: {
        async move() {
            this.$apollo.mutate({
                mutation: gql`mutation{moveLoyaltyToTokens}`,
                variables: {
                    amount: this.amount
                },
            }).then(() => {
                this.$router.go(0);
            })
        },
        goToZlatibor() {
            this.$router.push('/diamond-club-zlatibor');
        },
    }
}
</script>
<style scoped src="bootstrap-vue/dist/bootstrap-vue.css"></style>
<style>
.tooltip {
    max-width: none !important;

}

.tooltip-inner {
    background-color: rgba(65, 167, 229, 0.95);
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-top .arrow::before {
    border-bottom-color: rgba(65, 167, 229, 0.95);
    border-top-color: rgba(65, 167, 229, 0.95);
}


@media (min-width: 1200px) {
    .col-xl-20percent {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}
</style>