<template>
    <div class="card mb-3" style="border: 1px solid gray">
        <div class="card-header text-unset text-left"
            style="height: auto; margin-left: auto; margin-right: auto; border-bottom-width: 0px;">
            Freedom Program Portfolio
        </div>
        <div class="card-body align-center" style="padding-top: 1.25rem;
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;">

            <div class="dropdown-menu-header" style=" margin-bottom: 0px;">

                <div
                    :style="'height: 24px; background-color: ' + getFreedomPortfolioBG(freedomPortfolio.nextLevel) + '; padding-top:2px; padding-left:5px;padding-right:5px;display: flex; justify-content: space-between; align-items: center;'">
                    <span><span style="font-weight: bold;">Next level: </span> {{ freedomPortfolio.nextLevelName }}</span>
                    <span><span style="font-weight: bold;">Progress: </span> {{ freedomPortfolio.percentage }}%</span>
                    <span><span style="font-weight: bold;">Target: </span> {{ freedomPortfolio.nextLevelLimit }} HTL</span>
                </div>

                <div style="height: 140px; position: relative; width:100%; line-height:1;">

                    <div id="div2" style="width:100%;">
                        <!-- Content inside div2 goes here -->
                        <span class="insideDiv2">HTL Shares B:<br><span style="font-size:large;"><i>(Lifetime Dividend program)</i></span><br>{{ freedomPortfolio.shares }} </span>
                    </div>


                    <div
                        :style="'height: calc(' + (100 - freedomPortfolio.percentage) + '% - 12px); background-color: white;'">
                    </div>
                    <div
                        :style="'height: calc(' + freedomPortfolio.percentage + '% - 12px); background-color: ' + getFreedomPortfolioBG(freedomPortfolio.level) + ';'">
                    </div>
                    <div
                        :style="'height: 24px; background-color: ' + getFreedomPortfolioBG(freedomPortfolio.level) + '; border-top: 1px solid white; padding-top:2px; padding-left:5px; padding-right:5px;display: flex; justify-content: space-between; align-items: center;'">
                        <span><span style="font-weight: bold;">Current level: </span> {{ freedomPortfolio.levelName
                        }}</span>
                        <span><span style="font-weight: bold;">Target: </span> {{ freedomPortfolio.levelLimit }} HTL</span>
                    </div>

                </div>


            </div>



        </div>

    </div>
</template>

<script>

export default {
    name: "Rank",
    props: [
        "freedomPortfolio"
    ],
    components: {

    },
    computed: {

    },
    methods: {

        getFreedomPortfolioBG(level) {
            switch (level) {
                case 0: return 'white';
                case 1: return '#FFC000';
                case 2: return '#BF9000';
                case 3: return '#C55A11';
                case 4: return '#7F6000';
                case 5: return '#7C7C7C';
                case 6: return '#525252';
                case 7: return '#548235';
                case 8: return '#385723';
                case 9: return '#843C0C';
            }

        }

    }
}
</script>

<style scoped>
#div2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    text-align: center;

}

.insideDiv2 {
    color: white;
    font-size: x-large;
    /* Webkit browsers */
    -webkit-text-stroke: 1px black;
    /* Standard syntax */
    text-stroke: 1px black;
    font-weight: 700;
}
</style>