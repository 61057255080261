<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body"><span v-html="subheading" />
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar, faPlus } from "@fortawesome/free-solid-svg-icons";

library.add(faStar, faPlus);

export default {
  props: {
    icon: String,
    heading: String,
    subheading: String
  }
};
</script>
