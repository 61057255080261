<template>
    <div class="col-12 col-lg-6">
        <div class="card-shadow-primary card-border mb-3 card">
            <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-primary">
                    <div class="menu-header-image opacity-4 dd-header-bg-3"></div>
                    <div class="menu-header-content d-flex justify-content-center">
                        <h5 class="menu-header-title mx-auto">{{ $t('rank-high') }}: {{ getRankName(maxRank) }}</h5>
                        <h5 class="menu-header-title mx-auto">{{ $t('rank-current') }}: {{ getRankName(rank) }}</h5>
                        <h5 class="menu-header-title mx-auto" v-if="rank < 11">{{ $t('rank-next') }}: {{ getRankName(rank +
                            1) }}
                        </h5>

                    </div>
                </div>
            </div>
            <ul class="list-group list-group-flush">
                <li class="p-0 list-group-item">
                    <div class="row" v-if="rank > 0">
                        <div class="col-6 d-flex align-items-center">
                            <rank-column leg="left" :volume="progress.progress[2]"
                                :volume-requirement="progress.maxProgress[2]" :rank="progress.progress[0]"
                                :rank-requirement="progress.downlineRank" />
                        </div>
                        <div class="col-6 d-flex align-items-center">
                            <rank-column leg="right" :volume="progress.progress[3]"
                                :volume-requirement="progress.maxProgress[3]" :rank="progress.progress[1]"
                                :rank-requirement="progress.downlineRank" />
                        </div>
                    </div>
                    <div class="m-3" v-if="rank === 0">
                        <span class="fsize-1">{{ $t('rank-no-pack') }}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import RankColumn from "@/components/Dashboard/RankColumn";
export default {
    name: "Rank",
    props: [
        "leg",
        "progress",
        "rank",
        "username",
        "volume",
        "maxRank",
        "degraded"
    ],
    components: {
        RankColumn
    },
    computed: {
        volumeRequirement() {
            if (this.rank < 3) return 0;
            if (this.rank < 4) return 200;
            if (this.rank < 5) return 600;
            if (this.rank < 6) return 1500;
            return 0;
        }
    },
    methods: {
        getRankName(val) {
            const rankName = [
                this.$i18n.t('rank-0'),
                this.$i18n.t('rank-1'),
                this.$i18n.t('rank-2'),
                this.$i18n.t('rank-3'),
                this.$i18n.t('rank-4'),
                this.$i18n.t('rank-5'),
                this.$i18n.t('rank-6'),
                this.$i18n.t('rank-7'),
                this.$i18n.t('rank-8'),
                this.$i18n.t('rank-9'),
                this.$i18n.t('rank-10'),
                this.$i18n.t('rank-11'),
                this.$i18n.t('rank-12'),
                this.$i18n.t('rank-13')
            ];
            return rankName[val];
        },
        getUntil() {
            if (new Date() < new Date(2021, 7, 21, 0)) return new Date("2021-08-20T12:00:00.000Z");
            else return new Date("2021-11-20T12:00:00.000Z")
        }
    }
}
</script>

<style scoped>

</style>