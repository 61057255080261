<template>
    <div class="w-100">
        <div class="widget-chart mt-2">
            <div class="widget-chart-content">

                <div class="no-results">
                    <div class="results-subtitle fsize-1">{{$t('rank')}} {{ getRankName(rankRequirement) }} {{ leg }}: {{ hasRank }}</div>
                </div>
            </div>
        </div>
        <div class="divider mt-0 mb-0 mr-2"></div>
        <div class="widget-chart mb-2">
            <div class="widget-chart-content d-inline-block w-100">
                <div class="widget-numbers-sm mt-0 text-primary font-weight-bold fsize-2" :class="{'text-primary': volumeRequirement > volume, 'text-success': volume >= volumeRequirement }">
                    {{volume}}
                    <small>€</small>
                </div>
                <div class="widget-subheading fsize-05">{{$t("leg-volume", [legName])}}</div>
            </div>
            <div class="">
                <div class="progress-bar-sm progress-bar-animated progress">
                    <div class="progress-bar" role="progressbar" :aria-valuenow="volume" aria-valuemin="0"
                         :aria-valuemax="volumeRequirement" :style="'width: ' + Math.min(1, volume / volumeRequirement) * 100 + '%;'" v-bind:class="{'bg-primary': volumeRequirement > volume, 'bg-success': volume >= volumeRequirement }">
                    </div>
                </div>
                <div class="progress-sub-label">
                    <div class="sub-label-left fsize-05" v-if="volume < volumeRequirement">
                        {{$t('volume-requirement', [volumeRequirement, legName])}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RankColumn",
    props: [
        "volume",
        "volumeRequirement",
        "rank",
        "rankRequirement",
        "leg"
    ],
    computed: {
        hasRank() {
            return 1 > this.rank ? this.$i18n.t('no') : this.$i18n.t('yes')
        },
        legName() {
            switch (this.leg) {
                case 'left':
                    return this.$i18n.t('leg-left')
                case 'right':
                    return this.$i18n.t('leg-right')
                default:
                    return this.$i18n.t('leg-auto')
            }
        }
    },

    methods: {
        getRankName(val) {
            const rankName = [
                this.$i18n.t('rank-0'),
                this.$i18n.t('rank-1'),
                this.$i18n.t('rank-2'),
                this.$i18n.t('rank-3'),
                this.$i18n.t('rank-4'),
                this.$i18n.t('rank-5'),
                this.$i18n.t('rank-6'),
                this.$i18n.t('rank-7'),
                this.$i18n.t('rank-8'),
                this.$i18n.t('rank-9'),
                this.$i18n.t('rank-10'),
                this.$i18n.t('rank-11'),
            ];
            return rankName[val];
        }
    }
}
</script>

<style scoped>

.widget-chart {
    padding: 0 1rem 0 1rem;
}

.no-results {
    padding: 0.5rem;
}
</style>