<template>
    <div class="w-100">
        <apexchart height="350" width="100%" type="area" :options="chartOptions" :series="chartData"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    name: "FullChart",
    components: {
        apexchart: VueApexCharts
    },
    props: [
        "chartData"
    ],
    data: function() {
        return {
            chartOptions: {
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: "smooth",
                    width: 3
                },
                xaxis: {
                    type: 'datetime',
                    min: new Date('18 Dec 2018').getTime(),
                    tickAmount: 6,
                },
                yaxis: {
                    min: 0.4,
                    decimalsInFloat: 2,
                    tickAmount: 4
                },
                chart: {
                    toolbar: {
                        show: false
                    }
                }
            },
        };
    }
};
</script>


<style scoped>

</style>