<template>
    <div class="card">
        <div class="card-header text-unset text-left">
            {{ $t('referral-header')}}
        </div>
        <div class="d-flex card-body align-center">
            <span class="fsize-1">
                {{generateLink(inviteId)}}
            </span>
            <v-btn v-if="inviteId"
                   color="primary"
                   class="mx-2 my-0 copy-button"
                   @click="copyAddress"
            >
                <font-awesome-icon :icon="['far', 'clone']" small />
            </v-btn>
            <v-snackbar
                v-model="snackbar"
                :multi-line="multiLine"
            >
                {{ text }}
                <template v-slot:action="{ attrs }">
                    <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                        {{ $t('close') }}
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
        <div class="card-footer">
            {{$t('current-direction')}}
            <select-direction-menu :leg.sync="leg" />
        </div>
    </div>
</template>
<script>
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faClone } from '@fortawesome/free-regular-svg-icons'
    library.add(faClone);
    import SelectDirectionMenu from "../Tree/SelectDirectionMenu";
    export default {
        name: "Affiliate",
        components: {
            SelectDirectionMenu,
            "font-awesome-icon": FontAwesomeIcon,
        },
        props: [
            "inviteId",
            "leg"
        ],
        data() {
            return {
                multiLine: true,
                snackbar: false,
                text: this.$i18n.t('referral-copy'),
            }
        },
        methods: {
            copyAddress() {
                this.$copyText(this.generateLink(this.inviteId)).then(() => this.snackbar = true)
            },
          generateLink: function(inviteId) {
              return this.baseURL+"/#/sign-up/" + inviteId;
          }
        }
    }
</script>
<style scoped>
.copy-button {
    max-width: 32px !important;
    min-width: 30px !important;
}
</style>