<template>
    <div style="display:inline;">
        <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn small color="primary" class="text-unset" style="margin: 0;" v-on="on">To shares B (+10%)</v-btn>
            </template>

            <v-card>
                <v-card-title class="headline grey lighten-2">
                    Convert Available HTL into Shares B
                </v-card-title>
                <v-card-text>
                    <div v-if="available < 0.01">
                        The amount of Available HTL is too small. Make a new deposit and try again.
                    </div>
                    <b-form-group v-else label="How much Available HTL you want to convert into Shares B?">
                        <b-form-input min="0" :max="{ available }" id="valueOfAvailable" class="mb-2 mr-sm-2 mb-sm-0"
                            type="number" v-model="quantity">
                        </b-form-input>
                        <div v-if="isError === true" style="color:red; font-size: small;">{{ errorMsg }}</div>
                    </b-form-group>
                </v-card-text>



                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false" class="text-unset">
                        Back
                    </v-btn>
                    <v-btn v-if="available >= 0.01" @click="convert" text class="text-unset" color="primary">
                        Convert Available HTL into Shares B
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog2Confirmation" max-width="500px">
            <v-card elevation="20">
                <v-card-title class="headline grey lighten-2" primary-title>Confirm the convertion </v-card-title>
                <v-card-title class="headline">
                    <template>
                        <div>Confirm the convertion of {{ quantity }} Available HTL to {{ (quantity * 1.1).toFixed(2) }}
                            Shares B?</div>
                        <div style="color:red;">The convertion is irreversible.</div>
                    </template>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <template>
                        <v-btn class="text-unset" small light flat @click="dialog2Confirmation = false">No</v-btn>
                        <v-btn color="primary" class="text-unset" small dark @click="confirm">Yes</v-btn>
                    </template>

                </v-card-actions>
            </v-card>
        </v-dialog>


    </div>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";


export default {
    props: [
    ],
    data() {
        return {
            dialog: false,
            dialog2Confirmation: false,
            quantity: 0,
            isError: false,
            errorMsg: ""
        }
    },
    computed: {
        ...mapGetters({
            htlEur: 'prices/htlEur',
            available: 'user/available'
        }),
    },
    methods: {
        convert() {
            console.log("available", (this.available / 10e7).toFixed(2));
            console.log("quantity", this.quantity);
            if (this.quantity <= 0) {
                this.isError = true;
                this.errorMsg = "Enter a value greater than 0.";
                return;
            }
            else if (this.quantity > (this.available / 10e7)) {
                this.isError = true;
                this.errorMsg = "Enter a value less then " + (this.available / 10e7).toFixed(2) + '.';
                return;
            }
            else {
                this.isError = false;
                this.dialog2Confirmation = true;
            }
        },
        confirm() {
            this.dialog2Confirmation = false;
            this.dialog = false;
            this.$apollo.mutate({
                // Query
                mutation: gql`mutation($quantity:Float!, $rate:Float!){availableToSharesB(quantity:$quantity,rate:$rate)}`,
                // Parameters
                variables: {
                    quantity: parseFloat(this.quantity),
                    rate: this.htlEur
                },
            }).then(() => {
                this.$router.go(0);
            })
        },
        send() {

        }
    },
}
</script>