<template>
        <div class="col-12">
            <div class="card mb-3">
                <div class="card-header fsize-1 text-unset text-left">
                    {{$t('chart-title')}}
                </div>
                <div class="d-flex card-body">
                    <FullChart :chart-data="chartData"></FullChart>
                </div>
            </div>
        </div>
</template>

<script>
import FullChart from "@/components/Dashboard/charts/FullChart";
export default {
    name: "Charts",
    props: [
        "chartData"
    ],
    components: {
        FullChart,

    }
}
</script>

<style scoped>


</style>